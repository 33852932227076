import * as React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import {
  HeroTitle,
  IntroContainer,
  IntroSpacing,
  GalleryWrapper,
  GalleryImg,
} from "../components/styles/styles";
import Navbar from "../components/navbar";
import Container from "../components/container";

const Galleri = ({ data }) => {
  return (
    <Layout>
      {/* <main>
        <IntroContainer>
          <Navbar />
          <IntroSpacing>
            <Container>
              <HeroTitle>Galleriet</HeroTitle>
              <p className="title-font centered-text">Finn din inspiration.</p>
            </Container>
          </IntroSpacing>
          <GalleryWrapper className="section">
            {data.images.images.map((img, i) => {
              return <GalleryImg key={i} image={img.gatsbyImageData} alt="" />;
            })}
          </GalleryWrapper>
        </IntroContainer>
      </main> */}
    </Layout>
  );
};

export default Galleri;

export const query = graphql`
  query GalleriQuery {
    images: contentfulGallery {
      images {
        gatsbyImageData(
          layout: CONSTRAINED
          placeholder: BLURRED
          width: 800
          quality: 100
        )
      }
    }
  }
`;
